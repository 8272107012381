import heart from "../../assets/AboutUs/heart.svg";

const AboutUsPic = () => (
  <img
    src={heart}
    className="heart"
    alt="heart"
  />
);

export default AboutUsPic;